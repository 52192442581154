.selectInfo {
    display: flex;
    align-items: center;

    // emoji
    & div:first-child {
        font-size: 20px;
    }
}

.phoneSelect {
    & > * {
        height: 35px !important;
    }
}
