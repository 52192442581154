.ribbonGreen,
.ribbon {
    position: absolute;
    overflow: hidden;
    top: -10px;
    left: -10px;
    width: 103px;
    height: 103px;

    span {
        position: absolute;
        display: block;
        width: 150px;
        padding: 8px 0;
        background-color: #3498db;
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
        color: #fff;
        text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5), 0 0 3px rgba(245, 251, 255, 0.5), 0 0 4px rgba(245, 251, 255, 0.5),
            0 0 8px rgba(245, 251, 255, 0.5), 0 0 12px rgba(245, 251, 255, 0.5);
        font-variant: small-caps;
        text-align: center;
        left: -35px;
        top: 25px;
        transform: rotate(-45deg);
        z-index: 1;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
    }

    &::before,
    &::after {
        position: absolute;
        content: '';
        display: block;
        border: 5px solid #2980b9;
        border-top-color: transparent;
        border-left-color: transparent;
    }

    &::before {
        top: 0;
        right: 0;
    }

    &::after {
        bottom: 0;
        left: 0;
    }
}

.ribbonGreen {
    span {
        background-color: #805ad5;
        color: #fff;
    }

    &::before,
    &::after {
        border: 5px solid darken(#805ad5, 0.2);
    }
}
