.blockButton {
    position: relative;

    &:hover {
        &::after {
            content: ' ';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 4rem;
            width: 91.666667%;
            margin: 0 auto;
            z-index: 1;
            background-color: #bee3f8;
            border-radius: 2px;
            border: 1px solid #90cdf4;
            pointer-events: none;
        }
    }
}

.nowIndicator {
    &::after {
        content: ' ';
        border-bottom: 1px dashed red;
        height: 1px;
        left: 0;
        right: 0;
        top: 0;
        position: absolute;
        z-index: 3;
    }

    &::before {
        content: ' ';
        height: 10px;
        width: 10px;
        left: -10px;
        border-radius: 999px;
        background-color: red;
        position: absolute;
        top: -5px;
    }
}
